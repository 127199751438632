<template>
  <div
    :class="`sn-view__product--${product.name}`"
  >
    <section class="sn-container-content-wrapper sn-view__product-head sn-b-white">
      <div class="sn-container-content">
        <div class="sn-product__head-block">
          <div class="sn-product__head-block-content">
            <div class="sn-product__head-block-logo">
              <img
                :src="`/images/products_page/${product.name}_logo.svg`"
                :alt="product.name"
              >
            </div>
            <div class="sn-product__head-block-title">
              {{ product.title }}
            </div>
            <div
              v-if="$screen.lt.lg"
              class="sn-product__head-block-pic"
            >
              <img
                :src="`/images/products_page/${product.name}_pic.svg`"
                :alt="product.name"
              >
            </div>
            <div class="sn-product__head-block-desc">
              {{ product.desc }}
            </div>
            <v-btn
              tag="a"
              :to="product.link"
              target="_blank"
              color="green"
              size="b"
              :label="trans('action.go_over')"
              class="sn-upper sn-w-100 sn-maxw-400"
            />
          </div>
          <div
            v-if="$screen.gt.md"
            class="sn-product__head-block-pic"
          >
            <img
              :src="`/images/products_page/${product.name}_pic.svg`"
              :alt="product.name"
            >
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="product.stat.length > 0"
      class="sn-container-content-wrapper sn-view__product-stat sn-b-blue"
    >
      <div class="sn-container-content">
        <div class="sn-product__stat-wrapper">
          <div
            v-for="(item, itemIdx) in product.stat"
            :key="itemIdx"
            class="sn-product__stat"
          >
            <div class="sn-product__stat-icon">
              <img
                :src="`/images/products_page/${item.icon}.svg`"
              >
            </div>
            <div class="sn-product__stat-content">
              <div class="sn-product__stat-value">
                {{ item.value }}
              </div>
              <div class="sn-product__stat-label">
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="product.services.length > 0"
      class="sn-container-content-wrapper sn-view__product-services"
    >
      <div class="sn-container-content">
        <div class="sn-title sn-title--double sn-upper">
          <span>{{ trans('label.our_services') }}</span>
        </div>
        <div class="sn-product__service-wrapper">
          <div
            v-for="(item, itemIdx) in product.services"
            :key="itemIdx"
            class="sn-product__service"
          >
            <div class="sn-product__service-title">
              {{ item.title }}
            </div>
            <div class="sn-product__service-desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-auto col-md-4">
            <v-btn
              tag="a"
              :to="product.link"
              target="_blank"
              color="green"
              size="b"
              :label="trans('action.go_over')"
              class="sn-upper sn-w-100 sn-maxw-400"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VBtn from '@/components/VBtn';

export default {
  name: 'VProductPage',

  components: {
    VBtn,
  },

  mounted() {
    if (Object.keys(this.product).length === 0) {
      this.$router.push('/');
    }
  },

  computed: {
    product() {
      const res = this.$store.state.translator[this.$store.getters.interfaceLang].text.products
        .find((item) => item.name === this.$route.params.name);

      if (res) {
        return res;
      }

      return {};
    },
  },
};
</script>
